
import { importSync as i, macroCondition, getGlobalConfig } from '@embroider/macros';
let w = window;
let d = w.define;


i("./-embroider-implicit-modules.js");

d("tpk-website/app", function(){ return i("tpk-website/app.ts");});
d("tpk-website/config/environment", function(){ return i("tpk-website/config/environment.js");});
d("tpk-website/data/case-studies", function(){ return i("tpk-website/data/case-studies.ts");});
d("tpk-website/data/case-studies/abex", function(){ return i("tpk-website/data/case-studies/abex.ts");});
d("tpk-website/data/case-studies/blue-white", function(){ return i("tpk-website/data/case-studies/blue-white.ts");});
d("tpk-website/data/case-studies/crehopa", function(){ return i("tpk-website/data/case-studies/crehopa.ts");});
d("tpk-website/data/case-studies/technocite", function(){ return i("tpk-website/data/case-studies/technocite.ts");});
d("tpk-website/enums/field", function(){ return i("tpk-website/enums/field.ts");});
d("tpk-website/enums/service", function(){ return i("tpk-website/enums/service.ts");});
d("tpk-website/formats", function(){ return i("tpk-website/formats.js");});
d("tpk-website/router", function(){ return i("tpk-website/router.js");});
d("tpk-website/services/head-data", function(){ return i("tpk-website/services/head-data.ts");});
d("tpk-website/tailwind/tailwind.config", function(){ return i("tpk-website/tailwind/tailwind.config.js");});
d("tpk-website/utils/intl/missing-message", function(){ return i("tpk-website/utils/intl/missing-message.ts");});
d("tpk-website/services/current-changeset", function(){ return i("tpk-website/services/current-changeset.js");});
d("tpk-website/services/current-transition", function(){ return i("tpk-website/services/current-transition.js");});
d("tpk-website/services/download-file", function(){ return i("tpk-website/services/download-file.js");});
d("tpk-website/services/extended-store", function(){ return i("tpk-website/services/extended-store.js");});
d("tpk-website/services/fetch", function(){ return i("tpk-website/services/fetch.js");});
d("tpk-website/services/store-document", function(){ return i("tpk-website/services/store-document.js");});
d("tpk-website/utils/ensure-model-properties", function(){ return i("tpk-website/utils/ensure-model-properties.js");});
d("tpk-website/utils/file-to-form-data", function(){ return i("tpk-website/utils/file-to-form-data.js");});
d("tpk-website/utils/generic-diff", function(){ return i("tpk-website/utils/generic-diff.js");});
d("tpk-website/utils/sanitize-string", function(){ return i("tpk-website/utils/sanitize-string.js");});
d("tpk-website/utils/to", function(){ return i("tpk-website/utils/to.js");});
d("tpk-website/utils/window-history-back", function(){ return i("tpk-website/utils/window-history-back.js");});
d("tpk-website/instance-initializers/clear-double-boot", function(){ return i("tpk-website/instance-initializers/clear-double-boot.js");});
d("tpk-website/locations/none", function(){ return i("tpk-website/locations/none.js");});
d("tpk-website/services/fastboot", function(){ return i("tpk-website/services/fastboot.js");});
d("tpk-website/services/-ensure-registered", function(){ return i("tpk-website/services/-ensure-registered.js");});
d("tpk-website/component-managers/glimmer", function(){ return i("tpk-website/component-managers/glimmer.js");});
d("tpk-website/initializers/app-version", function(){ return i("tpk-website/initializers/app-version.js");});
d("tpk-website/flash/object", function(){ return i("tpk-website/flash/object.js");});
d("tpk-website/services/flash-messages", function(){ return i("tpk-website/services/flash-messages.js");});
d("tpk-website/instance-initializers/head-browser", function(){ return i("tpk-website/instance-initializers/head-browser.js");});
d("tpk-website/services/head-data", function(){ return i("tpk-website/services/head-data.js");});
d("tpk-website/services/intl", function(){ return i("tpk-website/services/intl.js");});
d("tpk-website/utils/intl/missing-message", function(){ return i("tpk-website/utils/intl/missing-message.js");});
d("tpk-website/services/loading", function(){ return i("tpk-website/services/loading.js");});
d("tpk-website/services/page-title-list", function(){ return i("tpk-website/services/page-title-list.js");});
d("tpk-website/services/page-title", function(){ return i("tpk-website/services/page-title.js");});
d("tpk-website/initializers/container-debug-adapter", function(){ return i("tpk-website/initializers/container-debug-adapter.js");});
d("tpk-website/modifiers/did-insert", function(){ return i("tpk-website/modifiers/did-insert.js");});
d("tpk-website/modifiers/did-update", function(){ return i("tpk-website/modifiers/did-update.js");});
d("tpk-website/modifiers/will-destroy", function(){ return i("tpk-website/modifiers/will-destroy.js");});
d("tpk-website/templates/application", function(){ return i("tpk-website/templates/application.hbs");});
d("tpk-website/application/controller", function(){ return i("tpk-website/application/controller.ts");});
d("tpk-website/application/route", function(){ return i("tpk-website/application/route.ts");});

  if (macroCondition(getGlobalConfig().fastboot?.isRunning)) {
d("tpk-website/initializers/ajax", function(){ return i("tpk-website/initializers/ajax.js");});
d("tpk-website/initializers/error-handler", function(){ return i("tpk-website/initializers/error-handler.js");});
  }


w._embroiderRouteBundles_ = [
  {
    names: ["about-us"],
    load: function() {
      return import("tpk-website/assets/_route_/about-us.js");
    }
  },
  {
    names: ["consultancy"],
    load: function() {
      return import("tpk-website/assets/_route_/consultancy.js");
    }
  },
  {
    names: ["cookie-policy"],
    load: function() {
      return import("tpk-website/assets/_route_/cookie-policy.js");
    }
  },
  {
    names: ["gcu"],
    load: function() {
      return import("tpk-website/assets/_route_/gcu.js");
    }
  },
  {
    names: ["index"],
    load: function() {
      return import("tpk-website/assets/_route_/index.js");
    }
  },
  {
    names: ["privacy-policy"],
    load: function() {
      return import("tpk-website/assets/_route_/privacy-policy.js");
    }
  },
  {
    names: ["products"],
    load: function() {
      return import("tpk-website/assets/_route_/products.js");
    }
  },
  {
    names: ["head"],
    load: function() {
      return import("tpk-website/assets/_route_/head.js");
    }
  },
  {
    names: ["work.details"],
    load: function() {
      return import("tpk-website/assets/_route_/work.details.js");
    }
  },
  {
    names: ["work.index"],
    load: function() {
      return import("tpk-website/assets/_route_/work.index.js");
    }
  },
  {
    names: ["work"],
    load: function() {
      return import("tpk-website/assets/_route_/work.js");
    }
  },
]



if (typeof FastBoot === 'undefined') {
  if (!runningTests) {
    require('tpk-website/app')['default'].create({"name":"tpk-website","version":"1.0.0+83471c8b"});
  }
}


